import React,{useEffect,useState} from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby'
import '../../assets/scss/index.scss';

import Footer from './components/Footer';
import Navbar from './components/Navbar';
import useSiteMetadata from './components/SiteMetadata';
import NewFooter from './components/NewFooter';

const SiteLayout = ({title,description,image,children}) => {
    console.log(title);
    console.log(title,description);
    console.log(image);
    console.log(children);
    if(image){
     image = image.split('/').pop()
    }
    // const { title, description } = useSiteMetadata();
    // let imgurl = 'img/og-image.jpg'

    const [imgFinalUrl, setimgFinalUrl] = useState(0);
    const [imgurl, setimgUrl] = useState(0);
    const [imgState, setImgState] = useState(false)
    const [titles, setTitle] = useState('')
    

    useEffect(() => {
        console.log('mounted');
        let curSiteUrl = window.location.href
        let finalSiteUrl = curSiteUrl.replace(window.location.pathname, "");
        console.log(finalSiteUrl);
        
        // let imgurl = image
        // let titles = description
        // if(imgurl){
        // let img = image.split('/').pop()
        // let ig = `/img/${img}`
        // console.log(img);
        // setimgUrl(ig)
        // setImgState(false)
        // setTitle(titles)
        // }


        // } else {
        //     let img = 'stepapp_logo.png'
        //     setimgUrl(img)
        //     let titlee =`STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
        //     e-learning software product that provides conceptual content delivery in a gamified format. Our
        //     team comes with deep experience and expertise gathered over many years of teaching and
        //     training students from various socio-economic strata of the country, preparing them for various
        //     reputed national and international competitive exams, and turning them into the best and
        //     brightest this country has ever seen.`
        //     setImgState(true)
        //     setTitle(titlee)

        // }

        // let imgFinalUrl = `${finalSiteUrl}${withPrefix('/')}${imgurl}`
        // setimgFinalUrl(imgFinalUrl)
        // console.log('imgurl',`${finalSiteUrl}${withPrefix('/')}${imgurl}`)
    }, []);
 console.log(image);
//  console.log(imgState);
//  console.log(titles);
//  console.log(imgState === true ? `${withPrefix('/')}img/stepapp_logo.png`: `${withPrefix('/')}img/${imgurl}`);

 


 console.log('working',`${withPrefix('/')}img/about_us.png`)
 console.log('not-working',`${withPrefix('/')}img/${imgurl}`)

 

    return (
        <div>
            <Helmet>
                <html lang="en" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${withPrefix('/')}img/favicon.ico`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/favicon.ico`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/favicon.ico`}
                    sizes="16x16"
                /> 
                <link
                    rel="mask-icon"
                    href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
                    color="#ff4400"
                />
                <meta name="theme-color" content="#fff" />
                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
                <meta
                    property="og:image"
                    // content={`${withPrefix('/')}img/How-Gamification-is-the-future-of-Education.jpg`}
                    // content={`img/${imgurl}`}
                    // content={`${withPrefix('/')}img/about_us.png`}
                    // content={`${withPrefix('/')}img/${imgurl}`}
                     content={`${withPrefix('/')}img/${image}`}

                   

                />
            </Helmet>
            <Navbar />
            <div>{children}</div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default SiteLayout;

