import React from 'react'; 
import { Link } from 'gatsby'; 
import logo from '@assets/img/logo.png';
import fb from '@assets/img/web_img/fb.png';
import twtr from '@assets/img/web_img/twtr.png';
import lin from '@assets/img/web_img/lin.png';
import insta from '@assets/img/web_img/insta.png';
import utube from '@assets/img/web_img/utube.png';
import location from '@assets/img/web_img/location.png';
import call from '@assets/img/web_img/call.png';
import mes from '@assets/img/web_img/mes.png';

 

const Footer = class extends React.Component {
render() {
return (
<footer>
   <div className="footer-top bg_dark">
      <div className="container">
         <div className="row">
            <div className="col-lg-3 col-md-6">
               <div className="footer-about mt-10">
                  <div className="footer_logo text-left"> 
                     <Link to="/"> 
                          <img src={logo} alt="STEPapp Logo | STEPapp" />
                     </Link>
                  </div>
 
                  <p className="pt-4">A unique, adaptive, personalised e-learning software solution that provides K12 learning content to students mapped to their school curriculum in an engaging, gamified format. Created by 400+ IITians and Doctors who have been a part of the education ecosystem for over 20 years. </p>  
                  <p className="pb-10 mt-3"><strong>STEPapp</strong> is brought to you by <strong>Eduisfun </strong><strong>Technologies Pvt Ltd</strong></p>
                  {/* <p className="powered-by"> </p> */}
                  {/* <p className="technologies"></p> */}
               </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
               <div className="footer-link mt-10">
                  <div className="footer-title pb-8">
                     <h6 className="">Quick Links</h6>
                  </div>
                  <ul>
                    <li><Link className="navbar-item" to="/">Home</Link></li> 
                    {/* <li><Link className="navbar-item" to="/programmes"> Products </Link></li> */}
                    <li><Link className="navbar-item" to="/product"> Products </Link></li>
                    {/* <li><Link className="navbar-item" to="/b2b"> School solution </Link></li> */}
                    <li><Link className="navbar-item" to="/schoolsolution"> School solution </Link></li>
                    <li><Link className="navbar-item" to="/partner"> Partner With Us </Link></li>
                    <li><Link className="navbar-item" to="/media"> In The Media </Link></li>
                    <li><Link className="navbar-item" to="/about"> About Us </Link> </li>
                    <li><Link className="navbar-item" to="/blog"> Blogs </Link></li>
                    <li><Link className="navbar-item" to="/faq"> FAQs </Link></li>
                    <li><Link className="navbar-item" to="/contact"> Contact Us </Link></li>  
                  </ul>
               </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
               <div className="footer-link support mt-10">
                  <div className="footer-title pb-8">
                     <h6 className="">Support</h6>
                  </div>
                  <ul>
                     <li><Link to="/term"><i className="fa fa-angle-right"></i>Terms Of Use</Link></li>
                     <li><Link to="/privacy"><i className="fa fa-angle-right"></i>Privacy Policy</Link></li>
                     {/* <li><a href="terms"><i className="fa fa-angle-right"></i>How To Use STEPapp</a></li> */}
                  </ul>
               </div>
            </div>
            <div className="col-lg-3 col-md-6">
               <div className="footer-address mt-10">
                  <div className="footer-title pb-8">
                     <h6 className="">Contact Us</h6>
                  </div>
                  <ul>
                     <li>
                        <div className="cont">
                           <img src={location} alt="location img" />
                           <p>117, Shopper's Point, Swami Vivekananda Rd, Andheri West, Mumbai, Maharashtra 400058</p>
                        </div>
                     </li>
                     <li>
                        <div className="cont">
                           <img src={call} alt="call img" />
                           <p><a href="tel:18002665007">18002665007</a></p>
                        </div>
                     </li>
                     <li>
                        <div className="cont">
                           <img src={mes} alt="message img" />
                           <p><a href="mailto:support@stepapp.ai">support@stepapp.ai</a></p>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div className="row copyright_section">
            <div className="col-md-8">
                <p><strong>© 2020 stepapp.ai All rights reserved.</strong></p> 
            </div>
            <div className="col-md-4 footer-social">
            <ul className="d-flex justify-content-center justify-content-md-end align-items-center">
                <li className="px-2">
                    <a href="https://www.facebook.com/stepappai/" target="_blank"> 
                        <img src={fb} alt="fb image" />
                    </a>
                </li>
                <li className="px-2"><a href="https://twitter.com/STEPappai?s=09" target="_blank">
                  <img src={twtr} alt="fb image" />
                </a></li>
                <li className="px-2">
                   <a href="https://in.linkedin.com/company/eduisfun-technologies-pvt-ltd" target="_blank">
                   <img src={lin} alt="linkedin image" />
                   </a></li>
                <li className="px-2"><a href="https://www.instagram.com/stepapp.ai/" target="_blank">
                   <img src={insta} alt="instagram image" />
                   </a>
                </li>
                <li className="px-2"><a href="https://www.youtube.com/channel/UCMly3tuVLkIXtrhyC8Xro7w" target="_blank">
                   <img src={utube} alt="instagram image" />
                   </a>
                </li>
            </ul>
            </div>
        </div>
      </div>
   </div>
</footer>
)
}
}
export default Footer;
 